<template>
  <section
    v-loading="loading"
    :element-loading-text="'Loading icons list, please wait...'"
  >
  <!-- <el-form>
    <title-and-description :field="field" />
  </el-form> -->
  
    <div class="searchbar-wrapper">
      <el-input
        type="text"
        v-model="searchQuery"
        placeholder="Search icons..."
        class="search-bar"
      >
        <template #prefix>
          <i class="el-icon-search"></i>
        </template>
      </el-input>
    </div>
    <div class="iconslist-container">
      <span
        v-for="icon in filteredIcons"
        :key="icon._id"
        class="custom-icon-wrapper"
        @click.prevent="selectIcon(icon)"
      >
        <div class="centered-content">{{ icon.name }}</div>
        <div
          v-html="icon.content"
          class="centered-content custom-icon"
        ></div>
      </span>
    </div>
  </section>
</template>

<script>
import { fetchAllIcons } from "@/repo/iconsRepo";

export default {
  async mounted() {
    this.loading = true;
    await this.fetchIcons();
  },
  components:{
    // "TitleAndDescription":()=>import("./TitleAndDescription"),
  },
  props: ["field"],
  data() {
    return {
      menuIcons: [],
      searchQuery: "",
      loading: false,
      selectedIcon: null,
    };
  },
  methods: {
    async fetchIcons() {
      try {
        let icons = await fetchAllIcons();
        this.menuIcons = icons;
        this.loading = false;
      } catch (error) {
        console.error("Error fetching icons:", error);
      }
    },
    selectIcon(icon) {
      this.selectedIcon = icon;
      this.$emit("icon-data", icon.name);
      this.field.label = icon.name;
    },
  },
  computed: {
    filteredIcons() {
      return this.menuIcons.filter((icon) => {
        return (
          icon.name &&
          icon.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
  },
};
</script>

<style scoped>
.searchbar-wrapper {
  display: flex;
  justify-content: center;
}

.search-bar {
  width: 50%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.iconslist-container {
  display: grid;
  grid-template-columns: repeat(6, 120px);
  grid-gap: 40px;
  justify-content: center;
  align-items: center;
  max-height: 75vh;
  scrollbar-width: thin;
  padding: 20px 0px;
}

.custom-icon-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.custom-icon {
}

.el-icon-search {
  margin-top: 18px;
  margin-left: 15px;
}

.selected .custom-icon {
  color: green;
}

.rectangle-shape .custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  background-color: lightgray;
}

.square-shape .custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: lightgray;
}


.circle-shape .custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: lightgray;
}

.selected .custom-icon-wrapper {
  background-color: lightblue; /* Adjust background color when icon is selected */
}
</style>
